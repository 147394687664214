<template>
  <div class="wrapper">
    <notifications />
    <side-bar>
      <template slot="links" slot-scope="props">
        <sidebar-item
          :link="{ name: 'Dashboards', icon: 'ni ni-shop text-primary' }"
        >
          <sidebar-item :link="{ name: 'Dashboard', path: '/dashboard' }" />
          <!--<sidebar-item :link="{ name: 'Alternative', path: '/alternative' }" />-->
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'Clientes', icon: 'ni ni-circle-08 text-orange' }"
        >
          <sidebar-item :link="{ name: 'Busqueda', path: '/clients' }" />
          <sidebar-item
            :link="{ name: 'Por validar', path: '/clientspending' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{ name: 'Prestamos', icon: 'ni ni-money-coins text-info' }"
        >
          <sidebar-item :link="{ name: 'Busqueda', path: '/loans' }" />
          <sidebar-item :link="{ name: 'Pagos', path: '/payrequests' }" />
          <sidebar-item
            :link="{ name: 'Crear pay review', path: '/create-payreview' }"
          />
          <sidebar-item
            :link="{
              name: 'Crear Pago',
              path: '/create-payment',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Simulador de Prórroga',
            icon: 'ni ni-money-coins text-info',
            path: '/loan-postponement-simulator',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Configuracion',
            icon: 'ni ni-settings-gear-65 text-info',
          }"
        >
          <sidebar-item
            :link="{ name: 'Configuracion General', path: '/configApp' }"
          />
        </sidebar-item>
      </template>
      <!-- <template slot="links-after">
          <hr class="my-3">
          <h6 class="navbar-heading p-0 text-muted">Validaciones</h6>
          <ul class="navbar-nav mb-md-3">
            <li class="nav-item">
              <sidebar-item :link="{ name: 'Clientes', icon: 'ni ni-single-02',path: '/validations' }"></sidebar-item>
            </li>
          </ul>
        </template>-->
      <!--
      <template slot="links-after">
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">
Documentation
</h6>
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/nuxt-argon-dashboard-pro/documentation"
              target="_blank"
              rel="noopener"
            >
              <i class="ni ni-spaceship" />
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/nuxt-argon-dashboard-pro/documentation/foundation/colors.html"
              target="_blank"
              rel="noopener"
            >
              <i class="ni ni-palette" />
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/nuxt-argon-dashboard-pro/documentation/components/avatars.html"
              target="_blank"
              rel="noopener"
            >
              <i class="ni ni-ui-04" />
              <span class="nav-link-text">Components</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/nuxt-argon-dashboard-pro/documentation/components/plugin_datepicker.html"
              target="_blank"
              rel="noopener"
            >
              <i class="ni ni-chart-pie-35" />
              <span class="nav-link-text">Plugins</span>
            </a>
          </li>
        </ul>
      </template>
      -->
    </side-bar>
    <div class="main-content">
      <dashboard-navbar
        :type="$route.name === 'alternative' ? 'light' : 'default'"
      />
      <div @click="$sidebar.displaySidebar(false)">
        <nuxt />
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

import DashboardNavbar from "~/components/layouts/argon/DashboardNavbar.vue"
import ContentFooter from "~/components/layouts/argon/ContentFooter.vue"
import DashboardContent from "~/components/layouts/argon/Content.vue"

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
  },
  mounted() {
    this.initScrollbar()
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win")
      if (isWindows) {
        initScrollbar("scrollbar-inner")
      }
    },
  },
}
</script>
<style lang="scss"></style>
