var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-nav",
    {
      staticClass: "navbar-top border-bottom navbar-expand",
      class: { "bg-success navbar-dark": _vm.type === "default" },
      attrs: { "container-classes": "container-fluid" },
    },
    [
      _c("ul", { staticClass: "navbar-nav align-items-center ml-md-auto" }, [
        _c("li", { staticClass: "nav-item d-xl-none" }, [
          _c(
            "div",
            {
              staticClass: "pr-3 sidenav-toggler",
              class: {
                active: _vm.$sidebar.showSidebar,
                "sidenav-toggler-dark": _vm.type === "default",
                "sidenav-toggler-light": _vm.type === "light",
              },
              on: { click: _vm.toggleSidebar },
            },
            [
              _c("div", { staticClass: "sidenav-toggler-inner" }, [
                _c("i", { staticClass: "sidenav-toggler-line" }),
                _vm._v(" "),
                _c("i", { staticClass: "sidenav-toggler-line" }),
                _vm._v(" "),
                _c("i", { staticClass: "sidenav-toggler-line" }),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item d-sm-none" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                href: "#",
                "data-action": "search-show",
                "data-target": "#navbar-search-main",
              },
            },
            [_c("i", { staticClass: "ni ni-zoom-split-in" })]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.user
        ? _c(
            "ul",
            { staticClass: "navbar-nav align-items-center ml-auto ml-md-0" },
            [
              _c(
                "base-dropdown",
                {
                  staticClass: "nav-item",
                  attrs: {
                    "menu-on-right": "",
                    tag: "li",
                    "title-tag": "a",
                    "title-classes": "nav-link pr-0",
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link pr-0",
                      attrs: { slot: "title-container", href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      slot: "title-container",
                    },
                    [
                      _c("div", { staticClass: "media align-items-center" }, [
                        _c(
                          "span",
                          { staticClass: "avatar avatar-sm rounded-circle" },
                          [
                            _c("img", {
                              attrs: {
                                alt: "Image placeholder",
                                src: _vm.user.photoURL,
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "media-body ml-2 d-none d-lg-block" },
                          [
                            _c(
                              "span",
                              { staticClass: "mb-0 text-sm font-weight-bold" },
                              [_vm._v(_vm._s(_vm.user.displayName))]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  [
                    _c("div", { staticClass: "dropdown-header noti-title" }, [
                      _c("h6", { staticClass: "text-overflow m-0" }, [
                        _vm._v("Welcome!"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      { staticClass: "dropdown-item", attrs: { href: "#!" } },
                      [
                        _c("i", { staticClass: "ni ni-single-02" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("My profile")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { staticClass: "dropdown-item", attrs: { href: "#!" } },
                      [
                        _c("i", { staticClass: "ni ni-settings-gear-65" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Settings")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { staticClass: "dropdown-item", attrs: { href: "#!" } },
                      [
                        _c("i", { staticClass: "ni ni-calendar-grid-58" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Activity")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { staticClass: "dropdown-item", attrs: { href: "#!" } },
                      [
                        _c("i", { staticClass: "ni ni-support-16" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Support")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dropdown-divider" }),
                    _vm._v(" "),
                    _c(
                      "a",
                      { staticClass: "dropdown-item", attrs: { href: "#!" } },
                      [
                        _c("i", { staticClass: "ni ni-user-run" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Logout")]),
                      ]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }