import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _53b30778 = () => interopDefault(import('../pages/alternative.vue' /* webpackChunkName: "pages/alternative" */))
const _f3eaf292 = () => interopDefault(import('../pages/calendar.vue' /* webpackChunkName: "pages/calendar" */))
const _68a4d08e = () => interopDefault(import('../pages/charts.vue' /* webpackChunkName: "pages/charts" */))
const _be6b66c2 = () => interopDefault(import('../pages/clients.vue' /* webpackChunkName: "pages/clients" */))
const _4e5df8c8 = () => interopDefault(import('../pages/clientspending.vue' /* webpackChunkName: "pages/clientspending" */))
const _7aadced6 = () => interopDefault(import('../pages/configApp.vue' /* webpackChunkName: "pages/configApp" */))
const _452bf524 = () => interopDefault(import('../pages/create-payment.vue' /* webpackChunkName: "pages/create-payment" */))
const _475b1c68 = () => interopDefault(import('../pages/create-payreview.vue' /* webpackChunkName: "pages/create-payreview" */))
const _c192f12a = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _0edf07f9 = () => interopDefault(import('../pages/dashboard_copy.vue' /* webpackChunkName: "pages/dashboard_copy" */))
const _31c9d6f8 = () => interopDefault(import('../pages/dashboard_tt.vue' /* webpackChunkName: "pages/dashboard_tt" */))
const _d7578202 = () => interopDefault(import('../pages/loan-postponement-simulator.vue' /* webpackChunkName: "pages/loan-postponement-simulator" */))
const _0d27434c = () => interopDefault(import('../pages/loans.vue' /* webpackChunkName: "pages/loans" */))
const _1b32aab8 = () => interopDefault(import('../pages/lock.vue' /* webpackChunkName: "pages/lock" */))
const _4ccec583 = () => interopDefault(import('../pages/payrequests.vue' /* webpackChunkName: "pages/payrequests" */))
const _06f8575d = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _35457f9c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _1592c1b9 = () => interopDefault(import('../pages/start.vue' /* webpackChunkName: "pages/start" */))
const _742009c6 = () => interopDefault(import('../pages/widgets.vue' /* webpackChunkName: "pages/widgets" */))
const _5560bac1 = () => interopDefault(import('../pages/components/buttons.vue' /* webpackChunkName: "pages/components/buttons" */))
const _5afe4ba3 = () => interopDefault(import('../pages/components/cards.vue' /* webpackChunkName: "pages/components/cards" */))
const _b58c6954 = () => interopDefault(import('../pages/components/grid-system.vue' /* webpackChunkName: "pages/components/grid-system" */))
const _16b903da = () => interopDefault(import('../pages/components/icons.vue' /* webpackChunkName: "pages/components/icons" */))
const _5f4e06a8 = () => interopDefault(import('../pages/components/notifications.vue' /* webpackChunkName: "pages/components/notifications" */))
const _0474961f = () => interopDefault(import('../pages/components/typography.vue' /* webpackChunkName: "pages/components/typography" */))
const _2267b922 = () => interopDefault(import('../pages/forms/components.vue' /* webpackChunkName: "pages/forms/components" */))
const _da6c75e0 = () => interopDefault(import('../pages/forms/elements.vue' /* webpackChunkName: "pages/forms/elements" */))
const _e931015c = () => interopDefault(import('../pages/forms/validation.vue' /* webpackChunkName: "pages/forms/validation" */))
const _059db970 = () => interopDefault(import('../pages/maps/google.vue' /* webpackChunkName: "pages/maps/google" */))
const _59cb1152 = () => interopDefault(import('../pages/maps/vector.vue' /* webpackChunkName: "pages/maps/vector" */))
const _60c80c36 = () => interopDefault(import('../pages/pages/timeline.vue' /* webpackChunkName: "pages/pages/timeline" */))
const _4292e26f = () => interopDefault(import('../pages/pages/user.vue' /* webpackChunkName: "pages/pages/user" */))
const _f0a2c8c8 = () => interopDefault(import('../pages/tables/paginated.vue' /* webpackChunkName: "pages/tables/paginated" */))
const _617b6aaa = () => interopDefault(import('../pages/tables/regular.vue' /* webpackChunkName: "pages/tables/regular" */))
const _d924aa0e = () => interopDefault(import('../pages/tables/sortable.vue' /* webpackChunkName: "pages/tables/sortable" */))
const _7253bea2 = () => interopDefault(import('../pages/client/_id.vue' /* webpackChunkName: "pages/client/_id" */))
const _1b037114 = () => interopDefault(import('../pages/loan/_id.vue' /* webpackChunkName: "pages/loan/_id" */))
const _12e79b02 = () => interopDefault(import('../pages/payreview/_id.vue' /* webpackChunkName: "pages/payreview/_id" */))
const _1e57ea5c = () => interopDefault(import('../pages/validations/_id.vue' /* webpackChunkName: "pages/validations/_id" */))
const _645b6e29 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/alternative",
    component: _53b30778,
    name: "alternative"
  }, {
    path: "/calendar",
    component: _f3eaf292,
    name: "calendar"
  }, {
    path: "/charts",
    component: _68a4d08e,
    name: "charts"
  }, {
    path: "/clients",
    component: _be6b66c2,
    name: "clients"
  }, {
    path: "/clientspending",
    component: _4e5df8c8,
    name: "clientspending"
  }, {
    path: "/configApp",
    component: _7aadced6,
    name: "configApp"
  }, {
    path: "/create-payment",
    component: _452bf524,
    name: "create-payment"
  }, {
    path: "/create-payreview",
    component: _475b1c68,
    name: "create-payreview"
  }, {
    path: "/dashboard",
    component: _c192f12a,
    name: "dashboard"
  }, {
    path: "/dashboard_copy",
    component: _0edf07f9,
    name: "dashboard_copy"
  }, {
    path: "/dashboard_tt",
    component: _31c9d6f8,
    name: "dashboard_tt"
  }, {
    path: "/loan-postponement-simulator",
    component: _d7578202,
    name: "loan-postponement-simulator"
  }, {
    path: "/loans",
    component: _0d27434c,
    name: "loans"
  }, {
    path: "/lock",
    component: _1b32aab8,
    name: "lock"
  }, {
    path: "/payrequests",
    component: _4ccec583,
    name: "payrequests"
  }, {
    path: "/pricing",
    component: _06f8575d,
    name: "pricing"
  }, {
    path: "/register",
    component: _35457f9c,
    name: "register"
  }, {
    path: "/start",
    component: _1592c1b9,
    name: "start"
  }, {
    path: "/widgets",
    component: _742009c6,
    name: "widgets"
  }, {
    path: "/components/buttons",
    component: _5560bac1,
    name: "components-buttons"
  }, {
    path: "/components/cards",
    component: _5afe4ba3,
    name: "components-cards"
  }, {
    path: "/components/grid-system",
    component: _b58c6954,
    name: "components-grid-system"
  }, {
    path: "/components/icons",
    component: _16b903da,
    name: "components-icons"
  }, {
    path: "/components/notifications",
    component: _5f4e06a8,
    name: "components-notifications"
  }, {
    path: "/components/typography",
    component: _0474961f,
    name: "components-typography"
  }, {
    path: "/forms/components",
    component: _2267b922,
    name: "forms-components"
  }, {
    path: "/forms/elements",
    component: _da6c75e0,
    name: "forms-elements"
  }, {
    path: "/forms/validation",
    component: _e931015c,
    name: "forms-validation"
  }, {
    path: "/maps/google",
    component: _059db970,
    name: "maps-google"
  }, {
    path: "/maps/vector",
    component: _59cb1152,
    name: "maps-vector"
  }, {
    path: "/pages/timeline",
    component: _60c80c36,
    name: "pages-timeline"
  }, {
    path: "/pages/user",
    component: _4292e26f,
    name: "pages-user"
  }, {
    path: "/tables/paginated",
    component: _f0a2c8c8,
    name: "tables-paginated"
  }, {
    path: "/tables/regular",
    component: _617b6aaa,
    name: "tables-regular"
  }, {
    path: "/tables/sortable",
    component: _d924aa0e,
    name: "tables-sortable"
  }, {
    path: "/client/:id?",
    component: _7253bea2,
    name: "client-id"
  }, {
    path: "/loan/:id?",
    component: _1b037114,
    name: "loan-id"
  }, {
    path: "/payreview/:id?",
    component: _12e79b02,
    name: "payreview-id"
  }, {
    path: "/validations/:id?",
    component: _1e57ea5c,
    name: "validations-id"
  }, {
    path: "/",
    component: _645b6e29,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
