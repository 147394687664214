export const strict = false

export const state = () => ({

})

export const mutations = {

}

export const actions = {
  async getClientTierByID({ commit }, { id = "" }) {
    const {data} = await this.$axios.get(`/tier/${id}`)
    return data
  },
}
