import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"


const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREREMENT_ID
}

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : ""

export const auth = firebase.auth()

export const google = new firebase.auth.GoogleAuthProvider().setCustomParameters(
  {
    hd: "kashin.app",
    state: "parangaricutirimicuaro",
  }
  )
export const firestore = firebase.firestore()

export default firebase
