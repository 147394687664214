var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-default" }, [
    _c("div", { staticClass: "header bg-success py-7 py-lg-8 pt-lg-9" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "header-body text-center mb-7" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-xl-5 col-lg-6 col-md-8 px-5" },
              [
                _c("h1", { staticClass: "text-white" }, [_vm._v("Ooops!")]),
                _vm._v(" "),
                _c("p", { staticClass: "text-lead text-white" }, [
                  _vm._v(
                    "Page not found. Don't worry though, we have plenty of other pages to explore"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "font-weight-bold text-white mt-5",
                    attrs: { to: "/dashboard" },
                  },
                  [_vm._v("Back to dashboard")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "separator separator-bottom separator-skew zindex-100" },
        [
          _c(
            "svg",
            {
              attrs: {
                x: "0",
                y: "0",
                viewBox: "0 0 2560 100",
                preserveAspectRatio: "none",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("polygon", {
                staticClass: "fill-default",
                attrs: { points: "2560 0 2560 100 0 100" },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "py-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row row-grid align-items-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              { staticClass: "pr-md-5" },
              [
                _c("h1", { staticClass: "text-light" }, [
                  _vm._v("Explore pages"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-light" }, [
                  _vm._v(
                    "If you want to get inspiration or just show something directly to your clients, you can jump start your development with our pre-built example pages."
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "font-weight-bold text-warning mt-5",
                    attrs: { to: "/pages/user" },
                  },
                  [_vm._v("Explore pages")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: { src: "img/theme/landing-2.png" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }