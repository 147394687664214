var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-layout", class: _vm.layoutClass }, [
    _c("div", { staticClass: "main-content" }, [_c("nuxt")], 1),
    _vm._v(" "),
    _c("footer", { staticClass: "py-5", attrs: { id: "footer-main" } }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "row align-items-center justify-content-xl-between" },
          [
            _c("div", { staticClass: "col-xl-6" }, [
              _c(
                "div",
                {
                  staticClass: "copyright text-center text-lg-left text-muted",
                },
                [
                  _vm._v(
                    "\n            © " + _vm._s(_vm.year) + "\n            "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold ml-1",
                      attrs: {
                        href: "https://kashin.app",
                        target: "_blank",
                        rel: "noopener",
                      },
                    },
                    [_vm._v("Kashin Team")]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }