export const strict = false

export const state = () => ({
  loans: [],
  payrequests: [],
  searchQueryLoans: "",
  searchQueryPayrequests: "",
})

export const mutations = {
  SET_CURRENT_LOANS(state, cl) {
    state.loans = cl
  },
  SET_PAYREQUESTS(state, cl) {
    state.payrequests = cl
  },
  SET_SEARCHQUERYLOANS(state, sq) {
    state.searchQueryLoans = sq
  },
  SET_SEARCHQUERYPAYREQ(state, sq) {
    state.searchQueryPayrequests = sq
  },
}

export const actions = {
  async getLoansRequests(
    { commit },
    {
      email = [],
      num_doc = [],
      state = "new,accepted",
      perPage = 5,
      currentPage = 1,
    }
  ) {
    const data = await this.$axios.get("/loans", {
      params: {
        state,
        email: email.join(","),
        numDoc: num_doc.join(","),
        perPage,
        currentPage,
      },
    })
    const loansrequests = []
    for (let p of data.data) {
      p.currentStartDate = p.currentStartDate.value.split("T")[0]
      p.initialMoney = `${p.initialMoney.currencyCode} ${p.initialMoney.amount}`
      loansrequests.push(p)
    }
    commit("SET_CURRENT_LOANS", loansrequests)
  },
  async getLoanByID({ commit }, { id }) {
    const { data } = await this.$axios.get(`/loan/${id}`)

    data.currentStartDate = data.currentStartDate.value.split("T")[0]
    data.currentEndDate = data.currentEndDate.value.split("T")[0]
    return data
  },
  async getPaytoday({ commit }, { id }) {
    const { data } = await this.$axios.get(`/loan/clientv2/${id}`)
    return data
  },
  async updateLoanContract({ commit }, { loan }) {
    await this.$axios.put(`/loan/changeInitalContract/${loan.id}`, {
      period: loan.period,
      duration: loan.duration,
    })
    return true
  },
  async updateLoanState({ commit }, { loan }) {
    console.log("\n\n\nloan.reschelePaymentRule")
    console.log(loan)
    await this.$axios.put(`/loan/state/${loan.id}`, {
      state: loan.state,
      whyClientRequested: loan.whyClientRequested,
      howClientWillPay: loan.howClientWillPay,
      deniedReason: loan.state === "denied" ? loan.deniedReason : null,
      reschedulePaymentRule: loan.reschedulePaymentRule,
      isLoanV2: loan.isLoanV2,
    })
    return true
  },
  async updateLoanEvents({ commit }, { loan }) {
    await this.$axios.put(`/loan/events/${loan.id}`, {
      state: loan.state,
      events: loan.events,
      whyClientRequested: loan.whyClientRequested,
      howClientWillPay: loan.howClientWillPay,
      reschedulePaymentRule: loan.reschedulePaymentRule,
    })
    return true
  },
  async getPayRequests(
    { commit },
    { email = [], num_doc = [], status = "new", perPage = 5, currentPage = 1 }
  ) {
    const data = await this.$axios.get("/loan/payrequests", {
      params: {
        email: email.join(","),
        numDoc: num_doc.join(","),
        status,
        perPage,
        currentPage,
      },
    })
    const payrequests = []
    for (let p of data.data) {
      p.money = `${p.money.currencyCode} ${p.money.amount}`
      payrequests.push(p)
    }
    commit("SET_PAYREQUESTS", payrequests)
  },

  async setSearchQueryLoans({ commit }, searchQuery) {
    commit("SET_SEARCHQUERYLOANS", searchQuery)
  },
  async setSearchQueryPayrequests({ commit }, searchQuery) {
    commit("SET_SEARCHQUERYPAYREQ", searchQuery)
  },
  async getPayRequestByID({ commit }, { id = "" }) {
    const { data } = await this.$axios.get(`/loan/payrequest/${id}`)
    return data
  },
  async updatePayRequest({ commit }, { id, payrequest }) {
    await this.$axios.put(`/loan/payrequest/${id}`, payrequest)
    return true
  },
  async createPayRequest({ commit }, { payrequest }) {
    await this.$axios.post(`/loan/payrequest`, payrequest)
    return true
  },
  async createPayment({ commit }, { transaction }) {
    await this.$axios.post(`/loan/create-payment`, transaction)
    return true
  },
  async resetPayout({}, { id = "" }) {
    const { data } = await this.$axios.post(`loan/reset-payout/${id}`)
    return data
  },
  async createPostponementLoanRequest({ commit }, { request }) {
    const { data } = await this.$axios.post(
      `/loan/create-postponement`,
      request
    )
    return data
  },
  async getExpectedPostponementLoan({ commit }, { request }) {
    const { data } = await this.$axios.post(
      `/loan/get-expected-postponement`,
      request
    )
    return data
  },
}
