<template>
  <div :class="layoutClass" class="auth-layout">
    <div class="main-content">
      <nuxt />
    </div>

    <footer id="footer-main" class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-lg-left text-muted">
              © {{ year }}
              <a
                href="https://kashin.app"
                class="font-weight-bold ml-1"
                target="_blank"
                rel="noopener"
                >Kashin Team</a
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    }
  },
  computed: {
    title() {
      return `${this.$route.name} Page`
    },
    layoutClass() {
      let exceptions = ["index", "home"]
      if (!exceptions.includes(this.$route.name)) {
        return "bg-default"
      } else {
        return ""
      }
    },
  },
  watch: {
    "$route.path"() {
      if (this.showMenu) {
        this.closeMenu()
      }
    },
  },
  methods: {
    closeMenu() {
      document.body.classList.remove("nav-open")
      this.showMenu = false
    },
  },
}
</script>
<style lang="scss">
.auth-layout {
  min-height: 100vh;
}
</style>
