import { encode, decode } from "@msgpack/msgpack"

let kws

export default ({ $config: { WS_URL } }, inject) => {
  if (!kws) {
    kws = () => {
      return {
        socket: null,
        types: {
          SET_STATUS: 1,
          REQ_STATE: 2,
        },
        connect: function () {
          if (this.socket) {
            this.socket.close()
          }
          this.socket = new WebSocket(WS_URL)
          this.socket.binaryType = "arraybuffer"
        },
        send: function (msg) {
          if (this.socket.readyState === WebSocket.CLOSED) {
            this.connect()
          } else {
            this.socket.send(encode(msg))
          }
        },
        onConnection: function (cb) {
          this.socket.addEventListener("open", function () {
            cb()
          })
        },
        listen: function (cb) {
          this.socket.addEventListener("message", function (event) {
            cb(decode(event.data))
          })
        },
      }
    }
  }
  inject("kws", kws())
}
