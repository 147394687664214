var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("notifications"),
      _vm._v(" "),
      _c("side-bar", {
        scopedSlots: _vm._u([
          {
            key: "links",
            fn: function (props) {
              return [
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Dashboards",
                        icon: "ni ni-shop text-primary",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Dashboard", path: "/dashboard" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Alternative", path: "/alternative" },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Clientes",
                        icon: "ni ni-circle-08 text-orange",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: { link: { name: "Busqueda", path: "/clients" } },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Examples",
                        icon: "ni ni-ungroup text-orange",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: { link: { name: "Pricing", path: "/pricing" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: { link: { name: "Login", path: "/login" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: { link: { name: "Register", path: "/register" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: { link: { name: "Lock", path: "/lock" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Timeline", path: "/pages/timeline" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: { link: { name: "Profile", path: "/pages/user" } },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Components",
                        icon: "ni ni-ui-04 text-info",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Buttons", path: "/components/buttons" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Cards", path: "/components/cards" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Grid", path: "/components/grid-system" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: {
                          name: "Notifications",
                          path: "/components/notifications",
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Icons", path: "/components/icons" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: {
                          name: "Typography",
                          path: "/components/typography",
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "sidebar-item",
                      { attrs: { link: { name: "Multi Level" } } },
                      [
                        _c("sidebar-item", {
                          attrs: {
                            link: { name: "Third level menu", path: "#" },
                          },
                        }),
                        _vm._v(" "),
                        _c("sidebar-item", {
                          attrs: {
                            link: { name: "Just another link", path: "#" },
                          },
                        }),
                        _vm._v(" "),
                        _c("sidebar-item", {
                          attrs: { link: { name: "One last link", path: "#" } },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Forms",
                        icon: "ni ni-single-copy-04 text-pink",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Elements", path: "/forms/elements" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Components", path: "/forms/components" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Validation", path: "/forms/validation" },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Tables",
                        icon: "ni ni-align-left-2 text-default",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Tables", path: "/tables/regular" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Sortable", path: "/tables/sortable" },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: {
                          name: "Paginated Tables",
                          path: "/tables/paginated",
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Maps",
                        icon: "ni ni-map-big text-primary",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: { link: { name: "Google", path: "/maps/google" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: { link: { name: "Vector", path: "/maps/vector" } },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sidebar-item", {
                  attrs: {
                    link: {
                      name: "Widgets",
                      icon: "ni ni-archive-2 text-green",
                      path: "/widgets",
                    },
                  },
                }),
                _vm._v(" "),
                _c("sidebar-item", {
                  attrs: {
                    link: {
                      name: "Charts",
                      icon: "ni ni-chart-pie-35 text-info",
                      path: "/charts",
                    },
                  },
                }),
                _vm._v(" "),
                _c("sidebar-item", {
                  attrs: {
                    link: {
                      name: "Calendar",
                      icon: "ni ni-calendar-grid-58 text-red",
                      path: "/calendar",
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("dashboard-navbar", {
            attrs: {
              type: _vm.$route.name === "alternative" ? "light" : "default",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.$sidebar.displaySidebar(false)
                },
              },
            },
            [_c("nuxt")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }