import { auth, google } from "~/plugins/firebase.js"

export const strict = false

export const state = () => ({
  userObject: null,
  user: null,
  token: null,
})

export const mutations = {
  SET_USER(state, user) {
    if (user) {
      user.photoURL = !user.photoURL ? "img/theme/profile.png" : user.photoURL
      user.displayName = !user.displayName ? "Unknown" : user.displayName
      state.user = user
    } else {
      state.user = null
    }
  },
  SET_USEROBJ(state, user) {
    state.userObject = user
  },
  SET_TOKEN(state, tk) {
    state.token = tk
  },
}

export const actions = {
  async logout({ commit }) {
    await auth.signOut()
    commit("SET_USER", null)
  },
  async login({ commit }, { email, password }) {
    try {
      await auth.createUserWithEmailAndPassword(email, password)
      const user = auth.currentUser
      await user.sendEmailVerification()
      commit("SET_USER", JSON.parse(JSON.stringify(user.user)))
    } catch (e1) {
      if (e1.code === "auth/email-already-in-use") {
        try {
          const user = await auth.signInWithEmailAndPassword(email, password)
          commit("SET_USER", JSON.parse(JSON.stringify(user.user)))
        } catch (e2) {
          throw new Error(e2)
        }
      } else {
        throw new Error(e1)
      }
    }
  },
  async loginWithGoogle({ commit }) {
    try {
      const { user } = await auth.signInWithPopup(google)
      if (user.email.split("@")[1] !== "kashin.app")
        throw new Error("Bad Email :S")
      commit("SET_USER", JSON.parse(JSON.stringify(user)))
    } catch (e) {
      throw new Error(e)
    }
  },
  async isLoggedIn() {
    const user = auth.currentUser
    if (user) return true
    else return false
  },
}
