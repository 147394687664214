var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("notifications"),
      _vm._v(" "),
      _c("side-bar", {
        scopedSlots: _vm._u([
          {
            key: "links",
            fn: function (props) {
              return [
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Dashboards",
                        icon: "ni ni-shop text-primary",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Dashboard", path: "/dashboard" },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Clientes",
                        icon: "ni ni-circle-08 text-orange",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: { link: { name: "Busqueda", path: "/clients" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: { name: "Por validar", path: "/clientspending" },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Prestamos",
                        icon: "ni ni-money-coins text-info",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: { link: { name: "Busqueda", path: "/loans" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: { link: { name: "Pagos", path: "/payrequests" } },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: {
                          name: "Crear pay review",
                          path: "/create-payreview",
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("sidebar-item", {
                      attrs: {
                        link: {
                          name: "Crear Pago",
                          path: "/create-payment",
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sidebar-item", {
                  attrs: {
                    link: {
                      name: "Simulador de Prórroga",
                      icon: "ni ni-money-coins text-info",
                      path: "/loan-postponement-simulator",
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "sidebar-item",
                  {
                    attrs: {
                      link: {
                        name: "Configuracion",
                        icon: "ni ni-settings-gear-65 text-info",
                      },
                    },
                  },
                  [
                    _c("sidebar-item", {
                      attrs: {
                        link: {
                          name: "Configuracion General",
                          path: "/configApp",
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("dashboard-navbar", {
            attrs: {
              type: _vm.$route.name === "alternative" ? "light" : "default",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.$sidebar.displaySidebar(false)
                },
              },
            },
            [_c("nuxt")],
            1
          ),
          _vm._v(" "),
          !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }