var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-layout", class: _vm.layoutClass },
    [
      _c(
        "base-nav",
        {
          staticClass: "navbar-horizontal navbar-main",
          attrs: {
            type: "light",
            transparent: true,
            "menu-classes": "justify-content-end",
            expand: "lg",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "navbar-wrapper",
              attrs: { slot: "brand" },
              slot: "brand",
            },
            [
              _c(
                "nuxt-link",
                { staticClass: "navbar-brand", attrs: { to: _vm.indexLink } },
                [
                  _c("img", {
                    attrs: { src: "img/brand/white.png", alt: "Logo white" },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          [
            _c("div", { staticClass: "navbar-collapse-header" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 collapse-brand" },
                  [
                    _c("nuxt-link", { attrs: { to: "/" } }, [
                      _c("img", {
                        attrs: {
                          src: "img/brand/green.png",
                          alt: "Logo green",
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 collapse-close" }, [
                  _c(
                    "button",
                    {
                      staticClass: "navbar-toggler",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showMenu = false
                        },
                      },
                    },
                    [_c("span"), _vm._v(" "), _c("span")]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "navbar-nav mr-auto" }, [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "nuxt-link",
                    { staticClass: "nav-link", attrs: { to: "/dashboard" } },
                    [
                      _c("span", { staticClass: "nav-link-inner--text" }, [
                        _vm._v("Dashboard"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "nuxt-link",
                    { staticClass: "nav-link", attrs: { to: "/pricing" } },
                    [
                      _c("span", { staticClass: "nav-link-inner--text" }, [
                        _vm._v("Pricing"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "nuxt-link",
                    { staticClass: "nav-link", attrs: { to: "/login" } },
                    [
                      _c("span", { staticClass: "nav-link-inner--text" }, [
                        _vm._v("Login"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "nuxt-link",
                    { staticClass: "nav-link", attrs: { to: "/register" } },
                    [
                      _c("span", { staticClass: "nav-link-inner--text" }, [
                        _vm._v("Register"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "nuxt-link",
                    { staticClass: "nav-link", attrs: { to: "/lock" } },
                    [
                      _c("span", { staticClass: "nav-link-inner--text" }, [
                        _vm._v("Lock"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr", { staticClass: "d-lg-none" }),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "navbar-nav align-items-lg-center ml-lg-auto" },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link nav-link-icon",
                      attrs: {
                        href: "https://www.facebook.com/creativetim",
                        target: "_blank",
                        rel: "noopener",
                        "aria-label": "Facebook",
                      },
                    },
                    [
                      _c("i", { staticClass: "fab fa-facebook-square" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "nav-link-inner--text d-lg-none" },
                        [_vm._v("Facebook")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link nav-link-icon",
                      attrs: {
                        href: "https://www.instagram.com/creativetimofficial",
                        target: "_blank",
                        rel: "noopener",
                        "aria-label": "Instagram",
                      },
                    },
                    [
                      _c("i", { staticClass: "fab fa-instagram" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "nav-link-inner--text d-lg-none" },
                        [_vm._v("Instagram")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link nav-link-icon",
                      attrs: {
                        href: "https://twitter.com/creativetim",
                        target: "_blank",
                        rel: "noopener",
                        "aria-label": "Twitter",
                      },
                    },
                    [
                      _c("i", { staticClass: "fab fa-twitter-square" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "nav-link-inner--text d-lg-none" },
                        [_vm._v("Twitter")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link nav-link-icon",
                      attrs: {
                        href: "https://github.com/creativetimofficial",
                        target: "_blank",
                        rel: "noopener",
                        "aria-label": "Github",
                      },
                    },
                    [
                      _c("i", { staticClass: "fab fa-github" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "nav-link-inner--text d-lg-none" },
                        [_vm._v("Github")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item d-none d-lg-block ml-lg-4" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-neutral btn-icon",
                        attrs: {
                          href: "https://www.creative-tim.com/product/nuxt-argon-dashboard-pro",
                          target: "_blank",
                          rel: "noopener",
                          "aria-label": "Purchase now",
                        },
                      },
                      [
                        _c("span", { staticClass: "btn-inner--icon" }, [
                          _c("i", { staticClass: "fas fa-shopping-cart mr-2" }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "nav-link-inner--text" }, [
                          _vm._v("Purchase now"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "main-content" }, [_c("nuxt")], 1),
      _vm._v(" "),
      _c("footer", { staticClass: "py-5", attrs: { id: "footer-main" } }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            {
              staticClass: "row align-items-center justify-content-xl-between",
            },
            [
              _c("div", { staticClass: "col-xl-6" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "copyright text-center text-lg-left text-muted",
                  },
                  [
                    _vm._v(
                      "\n            © " + _vm._s(_vm.year) + "\n            "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "font-weight-bold ml-1",
                        attrs: {
                          href: "https://www.creative-tim.com",
                          target: "_blank",
                          rel: "noopener",
                        },
                      },
                      [_vm._v("Creative Tim")]
                    ),
                    _vm._v("\n            &\n            "),
                    _c(
                      "a",
                      {
                        staticClass: "font-weight-bold ml-1",
                        attrs: {
                          href: "https://www.binarcode.com?ref=creative-tim",
                          target: "_blank",
                          rel: "noopener",
                        },
                      },
                      [_vm._v("Binar Code")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-6" }, [
      _c(
        "ul",
        {
          staticClass:
            "nav nav-footer justify-content-center justify-content-xl-end",
        },
        [
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: {
                  href: "https://www.creative-tim.com",
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v("Creative Tim")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: {
                  href: "https://www.creative-tim.com/presentation",
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v("About Us")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: {
                  href: "http://blog.creative-tim.com",
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v("Blog")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: {
                  href: "https://www.creative-tim.com/license",
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v("License")]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }