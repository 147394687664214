var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "no-ssr",
    [
      _c(
        "bread-crumb",
        { attrs: { "list-classes": "breadcrumb-links breadcrumb-dark" } },
        [
          _c(
            "BreadCrumbItem",
            [
              _c("nuxt-link", { attrs: { to: "/", "aria-label": "Home" } }, [
                _c("i", { staticClass: "fas fa-home" }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.$route.matched.slice(), function (route, index) {
            return _c(
              "BreadCrumbItem",
              {
                key: route.name,
                staticStyle: { display: "inline-block" },
                attrs: { active: index === _vm.$route.matched.length - 1 },
              },
              [
                index < _vm.$route.matched.length - 1
                  ? _c("nuxt-link", { attrs: { to: { name: route.name } } }, [
                      _vm._v("\n        " + _vm._s(route.name) + "\n      "),
                    ])
                  : _c("span", [_vm._v(_vm._s(route.name))]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }