export const strict = false

export const state = () => ({
  searchQuery: "",
  clients: [],
  currentClientCiius: [],
})

export const mutations = {
  SET_CLIENTS(state, clients) {
    state.clients = clients
  },
  SET_SEARCHQUERY(state, sq) {
    state.searchQuery = sq
  },
}

export const actions = {
  async searchClients(
    { commit },
    { email = [], num_doc = [], phoneNumber = [], perPage = 5, currentPage = 1 }
  ) {
    const data = await this.$axios.get("/clients", {
      params: {
        email: email.join(","),
        numDoc: num_doc.join(","),
        phoneNumber: phoneNumber.join(","),
        perPage,
        currentPage,
      },
    })
    const clients = data.data

    commit("SET_CLIENTS", clients)
  },
  async searchClientsToValidate(
    { commit },
    { email = [], num_doc = [], perPage = 5, currentPage = 1 }
  ) {
    const data = await this.$axios.get("/clients", {
      params: {
        email: email.join(","),
        numDoc: num_doc.join(","),
        perPage,
        currentPage,
        status: "toValidate",
      },
    })
    const clients = data.data

    commit("SET_CLIENTS", clients)
  },
  async getClientByID({ commit, state }, { id = "" }) {
    const { data } = await this.$axios.get(`/client/${id}`)
    return data
  },
  async blockClient({ commit, state }, cid = "") {
    await this.$axios.put("/client/block", {
      cid,
      block: "On",
    })
    return true
  },
  async unblockClient({ commit, state }, cid = "") {
    await this.$axios.put("/client/block", {
      cid,
      block: "Off",
    })
    return true
  },
  async updateClient({ commit }, { client }) {
    await this.$axios.put("/client", client)
    return true
  },
  async approveClient({ commit }, { client }) {
    await this.$axios.put("/approveClient", client)
    return true
  },
  async verifyClient({ commit }, { client }) {
    await this.$axios.put("/client/verifyClient", client)
    return true
  },
  async eliminateClient({ commit }, { client }) {
    await this.$axios.delete("/client/eliminateClient", { data: client })
    return true
  },

  async updateTierClient({ commit }, { clientTier }) {
    await this.$axios.put(`/tier/${clientTier.cid}`, clientTier)
    return true
  },
  async setSearchQuery({ commit }, searchQuery) {
    commit("SET_SEARCHQUERY", searchQuery)
  },
  async migrateClient({ commit }, { actualID, migrateID }) {
    const response = await this.$axios.put(`/client/migrate`, {
      actualID,
      migrateID,
    })
    return response
  },
}
