var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card widget-calendar" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "h5 text-muted mb-1 widget-calendar-year" }, [
        _vm._v(_vm._s(_vm.year)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "h3 mb-0 widget-calendar-day" }, [
        _vm._v(_vm._s(_vm.today)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c("full-calendar", {
          ref: "fullCalendar",
          attrs: {
            events: _vm.events,
            plugins: _vm.calendarPlugins,
            editable: true,
            defaultDate: "2018-12-01",
            contentHeight: "auto",
            theme: false,
            defaultView: _vm.defaultView,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }