export const state = () => ({
  metrics: [],
})

export const mutations = {
  SET_CURRENT_METRICS(state, cl) {
    state.metrics = cl
  },
}

export const actions = {
  async getMetrics({ commit }, { daysBack = 7 }) {
    const data = await this.$axios.get(`/metrics/${daysBack}`)
    commit("SET_CURRENT_METRICS", data.data)
  },
}
