var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-header",
        { staticClass: "pb-6 content__title content__title--calendar" },
        [
          _c("div", { staticClass: "row align-items-center py-4" }, [
            _c("div", { staticClass: "col-lg-6 col-7" }, [
              _c("h6", { staticClass: "h2 text-white d-inline-block mb-0" }, [
                _vm._v(_vm._s(_vm.$route.name)),
              ]),
              _vm._v(" "),
              _c(
                "nav",
                {
                  staticClass: "d-none d-md-inline-block ml-md-4",
                  attrs: { "aria-label": "breadcrumb" },
                },
                [_c("route-breadcrumb")],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-6 mt-3 mt-lg-0 text-lg-right" },
              [
                _c(
                  "a",
                  {
                    staticClass: "fullcalendar-btn-prev btn btn-sm btn-default",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.prev.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-angle-left" })]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fullcalendar-btn-next btn btn-sm btn-default",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.next.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-angle-right" })]
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    staticClass: "btn btn-sm btn-default",
                    class: { active: _vm.defaultView === "dayGridMonth" },
                    on: {
                      click: function ($event) {
                        return _vm.changeView("dayGridMonth")
                      },
                    },
                  },
                  [_vm._v("\n          Month\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    staticClass: "btn btn-sm btn-default",
                    class: { active: _vm.defaultView === "dayGridWeek" },
                    on: {
                      click: function ($event) {
                        return _vm.changeView("dayGridWeek")
                      },
                    },
                  },
                  [_vm._v("\n          Week\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    staticClass: "btn btn-sm btn-default",
                    class: { active: _vm.defaultView === "timeGridDay" },
                    on: {
                      click: function ($event) {
                        return _vm.changeView("timeGridDay")
                      },
                    },
                  },
                  [_vm._v("\n          Day\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid mt--6" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "card card-calendar" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body p-0 card-calendar-body" },
                [
                  _c("full-calendar", {
                    ref: "fullCalendar",
                    staticClass: "calendar",
                    attrs: {
                      events: _vm.events,
                      plugins: _vm.calendarPlugins,
                      editable: true,
                      contentHeight: "auto",
                      theme: false,
                      selectable: true,
                      selectHelper: true,
                      defaultView: _vm.defaultView,
                    },
                    on: {
                      dateClick: _vm.onDateClick,
                      eventClick: _vm.onEventClick,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { show: _vm.showAddModal, "modal-classes": "modal-secondary" },
          on: {
            "update:show": function ($event) {
              _vm.showAddModal = $event
            },
          },
        },
        [
          _c(
            "form",
            {
              staticClass: "new-event--form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveEvent.apply(null, arguments)
                },
              },
            },
            [
              _c("base-input", {
                attrs: {
                  label: "Event title",
                  placeholder: "Event Title",
                  "input-classes": "form-control-alternative new-event--title",
                },
                model: {
                  value: _vm.model.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "title", $$v)
                  },
                  expression: "model.title",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "form-control-label d-block mb-3" },
                  [_vm._v("Status color")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "btn-group btn-group-toggle btn-group-colors event-tag",
                  },
                  _vm._l(_vm.eventColors, function (color) {
                    return _c(
                      "label",
                      {
                        key: color,
                        staticClass: "btn",
                        class: [
                          color,
                          { "active focused": _vm.model.className === color },
                        ],
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.className,
                              expression: "model.className",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "event-tag",
                            autocomplete: "off",
                          },
                          domProps: {
                            value: color,
                            checked: _vm._q(_vm.model.className, color),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.model, "className", color)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                staticClass: "new-event--start",
                attrs: { type: "hidden" },
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "new-event--end",
                attrs: { type: "hidden" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary new-event--add",
                attrs: { type: "submit" },
                on: { click: _vm.saveEvent },
              },
              [_vm._v("Add event")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-link ml-auto",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.showAddModal = false
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            show: _vm.showEditModal,
            "modal-classes": "modal-secondary",
          },
          on: {
            "update:show": function ($event) {
              _vm.showEditModal = $event
            },
          },
        },
        [
          _c(
            "form",
            {
              staticClass: "edit-event--form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.editEvent.apply(null, arguments)
                },
              },
            },
            [
              _c("base-input", {
                attrs: {
                  label: "Event title",
                  placeholder: "Event Title",
                  "input-classes": "form-control-alternative new-event--title",
                },
                model: {
                  value: _vm.model.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "title", $$v)
                  },
                  expression: "model.title",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "form-control-label d-block mb-3" },
                  [_vm._v("Status color")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "btn-group btn-group-toggle btn-group-colors event-tag",
                  },
                  _vm._l(_vm.eventColors, function (color) {
                    return _c(
                      "label",
                      {
                        key: color,
                        staticClass: "btn",
                        class: [
                          color,
                          { "active focused": _vm.model.className === color },
                        ],
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.className,
                              expression: "model.className",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "event-tag",
                            autocomplete: "off",
                          },
                          domProps: {
                            value: color,
                            checked: _vm._q(_vm.model.className, color),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.model, "className", color)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("base-input", { attrs: { label: "Description" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.description,
                      expression: "model.description",
                    },
                  ],
                  staticClass:
                    "form-control form-control-alternative edit-event--description textarea-autosize",
                  attrs: { placeholder: "Event Desctiption" },
                  domProps: { value: _vm.model.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.model, "description", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "form-group--bar" }),
              ]),
              _vm._v(" "),
              _c("input", {
                staticClass: "new-event--start",
                attrs: { type: "hidden" },
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "new-event--end",
                attrs: { type: "hidden" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "base-button",
                {
                  staticClass: "new-event--add",
                  attrs: { "native-type": "submit", type: "primary" },
                  on: { click: _vm.editEvent },
                },
                [_vm._v("Update")]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                { attrs: { type: "danger" }, on: { click: _vm.deleteEvent } },
                [_vm._v("Delete")]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  staticClass: "ml-auto",
                  attrs: { type: "link" },
                  on: {
                    click: function ($event) {
                      _vm.showAddModal = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", { staticClass: "h3 mb-0" }, [_vm._v("Calendar")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }