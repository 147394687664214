import { auth } from "~/plugins/firebase.js"
if(!process.env.BASE_URL){
  process.env.FIREBASE_TOKEN = ''
}

export default function ({ $config: { BASE_URL }, $axios, store }) {
  $axios.setBaseURL(BASE_URL || "http://localhost:3000/api")
  $axios.onRequest((config) => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        store.commit("user/SET_TOKEN", idToken)
      })
    }
    const token = process.env.FIREBASE_TOKEN || store.state.user.token
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`
    }
    config.headers["Content-Type"] = "application/json"
  })
}
