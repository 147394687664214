var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white",
      attrs: { data: _vm.backgroundColor },
      on: {
        mouseenter: function ($event) {
          return _vm.$sidebar.onMouseEnter()
        },
        mouseleave: function ($event) {
          return _vm.$sidebar.onMouseLeave()
        },
      },
    },
    [
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "scrollbar-inner" },
        [
          _c(
            "div",
            { staticClass: "sidenav-header d-flex align-items-center" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "ml-auto" }, [
                _c(
                  "div",
                  {
                    staticClass: "sidenav-toggler d-none d-xl-block",
                    class: { active: !_vm.$sidebar.isMinimized },
                    on: { click: _vm.minimizeSidebar },
                  },
                  [_vm._m(1)]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "navbar-inner" },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav" },
                [
                  _vm._t("links", function () {
                    return _vm._l(_vm.sidebarLinks, function (link, index) {
                      return _c(
                        "sidebar-item",
                        { key: link.name + index, attrs: { link: link } },
                        _vm._l(link.children, function (subLink, index) {
                          return _c("sidebar-item", {
                            key: subLink.name + index,
                            attrs: { link: subLink },
                          })
                        }),
                        1
                      )
                    })
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("links-after"),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navbar-brand right" }, [
      _c("img", {
        staticClass: "navbar-brand-img",
        attrs: { src: "img/theme/logo.png", alt: "Sidebar logo" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidenav-toggler-inner" }, [
      _c("i", { staticClass: "sidenav-toggler-line" }),
      _vm._v(" "),
      _c("i", { staticClass: "sidenav-toggler-line" }),
      _vm._v(" "),
      _c("i", { staticClass: "sidenav-toggler-line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }