export const strict = false
export const state = () => ({
    info:[]
  })
  
export const mutations = {
    GET_INFO(state, info) {
      state.info = info
    },
    UPDATE_INFO(state, info) {
        state.info = info
      }
  }
export const actions = {
    async getInfo({ commit }) {
        const detailInfo = await this.$axios.get("/info")
        commit("GET_INFO", detailInfo.data)
        return detailInfo.data
    },
    async updateInfo({ commit },request) {
        const { content, topic, _id:id} = request
        const response = await this.$axios.patch("/info/update", {content,topic,id})
        commit("UPDATE_INFO", response)
        return response
    }
}